@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Inter;
    font-display: optional
  }
  body {
    @apply bg-white;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  h1, .h1 {
    font-family: 'Source Serif 4', serif;
    font-weight: 300;
    font-size: 3rem;
    line-height: 3.75rem;
    @apply font-normal;
    letter-spacing: -0.06rem;
    @apply text-aeroflowNavy;
  }
  h2, .h2 {
    font-family: 'Source Serif 4', serif;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 3rem;
    @apply font-normal;
    letter-spacing: -0.045rem;
    @apply text-aeroflowNavy;
  }
  h3, .h3 {
    font-family: 'Source Serif 4', serif;
    font-size: 1.75rem;
    line-height: 2.363rem;
    @apply font-semibold;
    letter-spacing: -0.035rem;
    @apply text-aeroflowNavy;
  }
  h4, .h4 {
    font-family: 'Source Serif 4', serif;
    font-size: 1.375rem;
    line-height: 2.063rem;
    @apply font-semibold;
    letter-spacing: -0.028rem;
    @apply text-aeroflowNavy;
  }
  h5, .h5 {
    font-family: 'Source Serif 4', serif;
    font-size: 1.125rem;
    line-height: 1.688rem;
    @apply font-normal uppercase;
    letter-spacing: .113rem;
    @apply text-aeroflowNavy;
  }
  .p-lg {
    font-size: 1.125rem;
    line-height: 1.688rem;
    @apply font-normal;
  }
  .p-strong, .p-default-strong {
    font-size: 1rem;
    line-height: 1.5rem;
    @apply font-semibold;
  }
  p, .p, .p-default, .p-md {
    font-size: 1rem;
    line-height: 1.5rem;
    @apply font-normal;
  }
  .p-sm {
    font-size: 0.875rem;
    line-height: 1.313rem;
    @apply font-normal;
  }
  .p-xs {
    font-size: 0.75rem;
    line-height: 1.125rem;
    @apply font-normal;
  }
  
  .a {
    @apply text-deepSleepBlue decoration-deepSleepBlue cursor-pointer;
  }

  .a-dark-background {
    @apply text-sleepBlue decoration-sleepBlue cursor-pointer;
  }

  .a:hover, .a-dark-background:hover {
    @apply underline;
  }

  label {
    @apply font-light
  }

  /* Removes counter spin buttons on Mozilla number inputs */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
}

@layer utilities {

  /* Begin Button Utilities */
  .btn {
    @apply w-full cursor-pointer my-2 focus:outline-none rounded-full font-normal text-base border border-solid border-transparent md:text-xl active:bg-black active:text-white hover:bg-white hover:text-graphite hover:border hover:border-solid hover:border-graphite;
    border-radius: 30px;
    padding: 8px 38px;
    max-width: 350px;
  }

  .btn:disabled {
    @apply bg-inactiveGray;
    color: #AFAFAF;
    border: none;
    cursor: not-allowed;
  }

  .btn-primary {
    @apply btn;
    @apply bg-ctaYellow text-graphite border-ctaYellow border border-solid border-transparent;
  }

  .btn-unfixed-height {
    @apply cursor-pointer px-6 focus:outline-none rounded-full font-normal;
    font-size: 1rem;
  }

  .btn-secondary-selected {
    @apply btn;
    @apply bg-aeroflowNavy text-white border-aeroflowNavy
  }

  .btn-secondary {
    @apply btn;
    @apply bg-deepSleepBlue text-white border-deepSleepBlue;
  }

  .payment-btn {
    @apply cursor-pointer border px-6 my-2 focus:outline-none w-3/4 font-semibold rounded-full bg-deepSleepBlue text-white border-deepSleepBlue hover:bg-ctaYellow hover:text-black hover:border-ctaYellow hover:shadow-none;
    box-shadow: 0 4px 0 0 rgba(0, 87, 168, 0.4);
  }

  .btn-danger {
    @apply btn;
    @apply bg-error text-white border-error border;
  }

  .btn-danger-secondary {
    @apply btn;
    @apply bg-white text-error border-error hover:bg-error hover:text-white;
  }

  .btn-shop {
    @apply btn;
    @apply bg-white hover:text-white hover:bg-black;
  }

  .btn-white {
    @apply btn;
    @apply btn bg-white border-white uppercase text-black;
  }

  .btn-quick-view {
    @apply text-sleepBlue text-base font-semibold flex items-center justify-center w-full max-w-[135px] mx-auto my-2 border rounded-lg border-sleepBlue
  }
  /* End Button Utilities */

  /* Begin Input Utilities */
  
  .input {
    @apply py-2 px-6 mb-2 w-full border font-semibold border-inputGray active:border-sleepBlue focus:border-sleepBlue rounded-md bg-white placeholder:text-inputGray;
    height: 40px;
    font-size: 1rem;
    font-weight: 400;
  }

  .input:focus-visible {
    @apply border-sleepBlue;
    outline: none;
  }

  .input::placeholder {
    @apply text-gray-300;
  }

  .month-input {
    @apply input text-lg md:text-xl;
    font-weight: 400;
  }

  .input-error {
    @apply input;
    @apply border-error;
    margin-bottom: 0;
  }

  .select-error {
    @apply input;
    @apply border-error bg-white text-error;
  }

  .input-success {
    @apply input;
    @apply border-success text-success bg-success bg-opacity-10;
  }

  .input-disabled {
    @apply input;
    @apply border-lightGray text-opacity-10;
  }

  .input-label {
    @apply font-light block p-1;
    font-size: 1rem;
    line-height: 1.35rem;
  }

  .input-asterisk {
    @apply font-semibold text-error;
    font-size: 1rem;
    gap: 0.625rem;
  }

  .brand-input-base {
    @apply text-base leading-snug tracking-wide rounded-full font-normal focus:outline-none text-left border;
  }

  .brand-input-placeholder-base {
    @apply font-light;
  }

  .success-input {
    @apply brand-input-base text-success border-success;
    background-color: rgba(18, 90, 71, 0.1);
  }

  .success-input::placeholder{
    @apply brand-input-placeholder-base text-success;
  }

  .default-input {
    @apply brand-input-base text-deepSleepBlue border-deepSleepBlue bg-white;
  }

  .default-input::placeholder {
    @apply brand-input-base text-gray-300
  }

  .error-input {
    @apply brand-input-base text-error border-error border;
    background-color: rgba(144, 20, 7, 0.1);
  }

  .error-input::placeholder {
    @apply brand-input-base text-error;
  }

  .disabled-input {
    @apply brand-input-base text-lightGray bg-lightGray border-lightGray;
  }

  .disabled-input::placeholder {
    @apply brand-input-base text-lightGray;
  }

  .themed-radio {
    accent-color: #2058A2; /* Firefox */
    background-color: #2058A2; /* Chrome */
    transform: scale(1.25);
  }

  /* radio button with a checkmark shown in checked state */
  .themed-radio--showCheck {
    appearance: none;
    -webkit-appearance: none; /* Safari */
    -moz-appearance: none; /* Firefox */
    width: 22px;
    height: 22px;
    @apply bg-white;
    @apply rounded-full;
    border: 1px solid #B4B7BC;
    outline: none;
    cursor: pointer;
  }

  .themed-radio--showCheck[type="radio"]:checked {
    @apply bg-deepSleepBlue;
    background-image: url(resources/images/intermediate-svg/checkmark-rounded--deepSeaBlue.svg);
    border: none;
  }
  
  .themed-checkboxes-snl input[type=checkbox]:checked {
    @apply bg-deepSleepBlue;
    border: none;
  }

  .themed-checkboxes-snl input[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url(resources/images/intermediate-svg/snl-check.svg);
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: left;
    border: none;
  }

  .themed-checkboxes input[type=checkbox], .themed-checkboxes-snl input[type=checkbox] {
    width: 1.3rem;
    height: 1.4rem;
    color: lightgray;
    -webkit-appearance: none;
    border-width: 1px;
    border-color: lightgray;
    outline: 0;
    border-radius: 4px;
    background: white none;
    transition: background 300ms;
    cursor: pointer;
  }
  .themed-checkboxes input[type=checkbox]::before, .themed-checkboxes-snl input[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 5px;
    background-color: transparent;
    background-size: contain;
  }
  
  .themed-checkboxes input[type=checkbox]:checked {
    @apply bg-deepSleepBlue;
  }

  .themed-checkboxes input[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url(resources/images/intermediate-svg/forms-check.svg);
    background-repeat: no-repeat;
    background-size: 19px;
    background-position: left;
  }

  .large-rounded-radio {
    width:18px;
    height:18px;
    border:1px solid black;
    border-radius:50%;
    outline:none;
    background-color: white;
    -webkit-appearance: none;
    cursor: pointer;
  }
  
  .large-rounded-radio:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    border-radius:50%;
    transition: background-color 0.35s ease-in-out;
  }
  .large-rounded-radio:checked:before {
    background-color: #0057a8;
  }

  .styled-select-arrow-container {
    background: #fff url(resources/images/intermediate-svg/carat-down.svg) no-repeat bottom 25px right 32px;
  }

  .styled-select-arrow-container select {
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @apply py-2 px-6 mb-2 w-full border border-deepSleepBlue font-semibold text-deepSleepBlue focus:outline-none rounded-full;
  }

  .tile-border {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
  }

  /* End Input Utilities */

  /* Misc Utilities */

  .box-shadow-light {
    box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.25);
  }

  .box-shadow-heavy {
    box-shadow: 4px 4px 8px -2px rgba(0,0,0,0.75);
  }

  .alert-promo {
    @apply bg-success text-white;
  }

  .alert-info {
    @apply bg-deepSleepBlue text-white;
  }

  .alert-danger {
    @apply bg-error text-black;
  }

  .min-width-sm {
    min-width: 320px;
  }
  .min-height-sm {
    min-height: 320px;
  }
  .min-height-md {
    min-height: 480px;
  }
  .min-height-semi-lg {
    min-height: 624px;
  }
  .min-height-lg {
    min-height: 768px;
  }

  .sticky-gradient {
    background-image: linear-gradient(to top, rgba(255,255,255,0) 5%, white 45% )
  }

  .sleep-gradient-lightBlueToLight {
    background: linear-gradient(289.34deg, #91C8DE 2.72%, #0992C8 50.84%, #677EB3 117.29%);
  }

  .sleep-gradient-lightToDark {
    background-image: linear-gradient(-71.98deg, #91C8DE -0.99%, #0992C8 51.63%, #677EB3 104.25%);
  }

  .sleep-gradient-darkToLight {
    background-image: linear-gradient(108.02deg, #91C8DE -0.99%, #0992C8 51.63%, #677EB3 104.25%);
  }

  /* hide bottom border on last step */
  .account-tracker :last-child .step-separator::after {
    display: none;
  }

  .footer-info p {
    @apply font-light;
  }

  .footer-info a {
    @apply text-hyperLinkBlue hover:underline;
  }

  .max-w-custom-inner {
    max-width: 1400px;
  }

  /* End Misc Utilities */

  /*@media (max-width: 767px){*/
  /*  .medchat-position-override {*/
  /*    right: 0;*/
  /*    bottom: 0;*/
  /*    width: 300px!important;*/
  /*    height: 85%!important;*/
  /*  }*/
  /*}*/

}
